<template>
    <login></login>
  
</template>

<script>
import login from "../components/pphLogin.vue";
export default {
    name: "pphlogin",
  data() {
    return {
        
    };
  },
  components: {
 login,
  },
};

</script>

<style>

</style>