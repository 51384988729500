<template>
  <div>
      <div class="py-4 px-4 lg:px-8 mx-0 lg:mx-8 justify-content-center" style="background-color: #F4F6F9;">
      <loading
    :active="isLoadingModel"
    :can-cancel="true"
    color="#274461"
    :is-full-page="fullPage"
  ></loading>
  <Toast />
  <ConfirmPopup></ConfirmPopup>
      <div id="card1" class="card d-flex justify-content-center">
          
          <div class="py-4 px-4 mx-0 lg:mx-8" id="contact" >
              <div class="grid justify-content-between">
                  <div class="col-12 md:col-6 align-content-center" style="margin-top:-1.5rem;">
                      <div class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3">
                          <img
          src="bg.jpg"
          class="w-12"
          alt="mockup mobile"
        />
                      </div>
                  </div>
              
                  <div class="col-12 md:col-6" style="margin-top:-1.5rem;">
                  
                      <div class=" top  ">
                        <img  src="pphlogo.png" style="width:100px ; align-items: center;">
                          <h4 style="color: #6b719b; font-size: 40px; font-weight: 700">Login</h4>
                         
                         
                      </div>
                 
                      <div>
                        <label for="email1" class="block text-900 text-xl font-medium mb-2">Email</label>
                        <InputText id="email1" type="text" placeholder="Email address" class="w-full  mb-5"
                            style="padding: 1rem" v-model="email" />

                        <label for="password1" class="block text-900 font-medium text-xl mb-2">Password</label>
                        <!-- <Password aria-expanded="false" v-model="password" placeholder="Password"  class="w-full mb-3" inputClass="w-full" :inputStyle="{ padding: '1rem' }"></Password> -->
                        <InputText id="password1" v-model="password" type="password" class="w-full mb-3"
                            placeholder="Password" style="padding: 1rem" @keypress.enter="Login" />
                        <div class="flex align-items-center justify-content-between mb-5 gap-5">
                            <div class="flex align-items-center">
                                <Checkbox v-model="checked" id="rememberme1" binary class="mr-2"></Checkbox>
                                <label for="rememberme1">Remember me</label>
                            </div>
                            <a class="font-medium no-underline ml-2 text-right cursor-pointer"
                                style="color: var(--primary-color)">Forgot password?</a>
                        </div>
                        <Button label="Sign In" class="w-full p-3 text-xl btn_light_blue" @click="Login"></Button>
                    </div>
                          
                      </div>
                    
                  </div>
              </div>
 
          </div>
          
         
         
         
            
     
      </div>
      <ScrollTop />
  </div>

</template>



<script>
import apis from "@/apis";

export default{

  data() {
        return {
            isLoadingModel:false,
			fullPage:true,
            email: '',
            password: '',
            checked: false,
            show:false,
            user_type:'',
            dropdownItems: [
					{name: 'Admin', value: 'admin'},
					{name: 'Teacher', value: 'teacher'},
					{name: 'Assistent Teacher', value: 'assteacher'},
                    {name: 'Student', value: 'student'},
                    {name: 'Network Administrator', value: 'network'}
				],
        }
    },
    methods:{
    async  Login()
      {
        
        if(this.email==="" ||this.email===null ||this.email===undefined)
        {
            //alert("Please Enter Username");
            this.$toast.add({severity:'error', summary: 'Error Message', detail:'Please Enter Username', life: 3000});
            return false;
        }
        if(this.password==="" ||this.password===null ||this.password===undefined)
        {
            //alert("Please Enter Password");
            this.$toast.add({severity:'error', summary: 'Error Message', detail:'Please Enter Password', life: 3000});
            return false;
        }
        
        var data = 
         {
                "email_id":this.email,  
                "password":this.password, 
         };
         this.isLoadingModel = true;
		var promise = apis.login(data);
        promise
        .then(response => { 
            this.isLoadingModel = false;
        if(response.data.status==true)
        {
            localStorage.clear();
            
            localStorage.user_key = response.data.data[0].user_type;
            localStorage.id = response.data.data[0]._id;
            localStorage.token = response.data.data[0].logintoken;
            localStorage.client_id = response.data.data[0].client_id?response.data.data[0].client_id:'';

            //for fleet manager
            localStorage.role_id = response.data.data[0].role_id[0].role_id;

            localStorage.setItem('user_profile',JSON.stringify(response.data.data[0]));
            
            var dashboard_link="";
           if (response.data.data[0].role_id && response.data.data[0].role_id[0].dashboard_link) 
           {  
             dashboard_link=response.data.data[0].role_id[0].dashboard_link.toString();

            localStorage.Breadcrumb_home = dashboard_link;  //for Breadcrumb_home

            this.$router.push({name:dashboard_link});
            
           }
           else
           {
              dashboard_link = '/dashboard';                    //for Breadcrumb_home
              localStorage.Breadcrumb_home = dashboard_link;    //for Breadcrumb_home

            this.$router.push('/dashboard');
           }
            
        } 
        else  
        {
              this.$toast.add({severity:'error', summary: 'Error Message', detail:response.data.message, life: 3000});
            return false;
        }  
            
            
        })
        .catch(error => {
            this.isLoadingModel = false;
            this.$toast.add({severity:'error', summary: 'Error Message', detail:'Invalid Login Credentials', life: 3000});
            return false;
            });
      }  
    },
    computed: {
        logoColor() {
            if (this.$appState.darkTheme) return 'white';
            return 'dark';
        }
    }
}

</script>


<style scoped>
.top{
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
}
#card1 {
    background-color: #fff;
    border-top: 3px solid #6b719b;
    margin-top:10%;
}

.link-style {
    color: #6b719b;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none; 
  }
  .link-style:hover {
    text-decoration: underline; /* Add underline on hover */
  }
  @media screen and (min-width: 992px)
{
.lg\:px-8 {
    padding-left: 15rem !important;
    padding-right: 15rem !important;
}
}
</style>